.maps-container {
  font-family: var(--font-family);
  position: relative;
  transition: all 0.2s ease;
}

.active-marker {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: floatAnimation 1s infinite alternate;
}

.active-marker svg {
  width: auto;
  height: 60px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}

.active-marker svg path {
  fill: #f1592a;
}

.active-marker span {
  position: absolute;
  font-size: 10px !important;
  font-weight: bold;
  color: #fff;
  padding-bottom: 1.1rem;
}

.white-active-marker svg path {
  fill: #fff;
}

.white-active-marker span {
  color: var(--color);
}

@keyframes floatAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em 0;
  color: #fff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
  transform: scale(0.8);
}

.marker:hover {
  transform: translateY(-5px);
}

.marker img {
  width: 15px;
  height: 15px;
}

.marker-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.25em;
}

.cluster {
  border-radius: 0;
  animation: pulse 2s infinite;
  color: #fff !important;
}

.popup {
  position: absolute;
  bottom: 2.5em;
  right: 5.5em;
  max-height: 600px;
  width: 500px;
  height: auto;
  color: #0f5671;
  display: flex;
  font-size: 14px;
  border-radius: 12px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  font-family: var(--font-family);
  transition: all 0.2s ease;
}

.popup-thumbnail {
  flex: 0 0 35%;
  width: 100%;
  height: 100%;
}

.popup-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.popup-favorite {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em 1em 0 0;
}

.popup-description-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 60%;
  padding: 1em;
  gap: 0.5em 0;
}

.popup-category-container {
  display: inline-block;
  margin-left: 0.5em;
  white-space: nowrap;
  vertical-align: middle;
  gap: 0 0.25em;
  width: fit-content;
  white-space: nowrap;
}

.popup-category-container > * {
  border-radius: 5px;
  background: var(--color);
  color: #fff;
  padding: 0.3em 0.5em;
  text-transform: capitalize;
  font-size: 10px;
}

.popup-header {
  display: flex;
  gap: 0 0.5em;
  align-items: center;
}

.popup-name {
  font-weight: 800;
  text-transform: capitalize;
}

.popup-type {
  text-transform: uppercase;
}

.popup-close {
  width: 17.5px;
  position: absolute;
  top: 0;
  left: -22px;
  margin-top: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
}

.modern-lifestyle-compare-popup {
  right: 50%;
  bottom: 50%;
  transform: scale(0.65) translate(80%, 70%);
  z-index: 5;
}

.favorite-icon {
  margin-top: 0.5em;
  margin-right: 0.5em;
  display: flex;
  justify-content: space-between;
}

.marker-details-title .add-to-favorites-icon {
  margin-left: auto;
}

.map-top-controls {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5em;
  top: 0;
  margin-top: 1em;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.compare-map-top-controls {
  z-index: 2;
}

.map-menu-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-60%);
  left: -65px;
}

.hide-map-menu-container {
  visibility: hidden;
}

.show-map-menu-container {
  visibility: visible;
}

.iphone-map-menu-container {
  left: -60px;
}

.lifestyle-menu-container {
  left: -85px;
}

.iphone-lifestyle-menu-container {
  left: -85px;
}

.lifestyle-compare-menu-container {
  left: -60px;
}

.iphone-lifestyle-compare-menu-container {
  left: -60px;
}

.map-top-controls-mobile {
  width: 60px;
  display: grid;
  align-items: center;
  gap: 0.8em;
  box-sizing: border-box;
  padding: 0.8em 1em;
  background: #fff;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.lifestyle-map-top-controls-mobile {
  width: 85px;
}

.menu-collapse-btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #fff;
  margin-left: 1em;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.menu-collapse-btn svg {
  width: auto;
  height: 15px;
  transition: transform 1s ease;
}

.open-menu .menu-collapse-btn {
  display: none;
  /* transform: rotate(180deg); */
}

.map-menu-container.open-menu {
  left: 0;
}

.map-menu-container > .map-top-controls-mobile {
  visibility: hidden;
}

.modern-map-menu-container .menu-collapse-btn,
.modern-map-menu-container .map-top-controls-mobile,
.modern-map-menu-container .map-top-controls-mobile .map-btn-wrapper {
  background: #000;
}

.modern-map-menu-container .map-top-controls-mobile .map-btn-wrapper {
  border: 0.8em solid #000;
}

.open-menu .map-top-controls-mobile {
  visibility: visible;
}

.open-desktop-sidebar svg,
.open-desktop-sidebar img {
  transform: scaleX(-1);
}

.desktop-collapse-btn {
  position: absolute;
  left: 0;
  top: calc(50vh - 2.5rem);
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 15px;
  background: var(--color);
  margin-left: 1em;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.desktop-collapse-btn img {
  width: auto;
  height: 1.25em;
  transition: transform 1s ease;
}

.modern-desktop-collapse-btn {
  width: auto;
  height: 45px;
  border-radius: 0px 10px 10px 0px;
  padding: 0.5em 0;
  margin-left: 0;
  z-index: 2;
  pointer-events: auto;
}

.modern-desktop-collapse-btn svg {
  width: 20px;
  height: auto;
}

.dashboard-type-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--type-color);
  border: 2px solid var(--type-color);
  background-color: transparent;
  gap: 0 0.5em;
  padding: 0.35em 0.5em;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.75em;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75em;
}

.modern-dashboard-type-btn {
  min-width: 75px;
  position: relative;
  flex-direction: column;
  gap: 0.25rem 0;
  color: #fff !important;
  background: none !important;
  font-weight: 500;
  font-size: 10px;
  border: none !important;
  border-radius: 5px;
  text-transform: capitalize;
  box-shadow: none !important;
}

.modern-custom-button-icon {
  position: relative;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modern-custom-button-icon > .icon-score-cirlce::before {
  top: -1.5px;
  left: -1.5px;
}

.modern-custom-button-icon > .score-diamond svg {
  width: 30px;
  height: 30px;
}

.modern-lifestyle-custom-button {
  width: 30px;
  height: 30px;
}

.modern-dashboard-type-btn .type-btn-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--type-color);
}

.modern-dashboard-type-btn .feature-icon path {
  fill: var(--type-color) !important;
}

.dashboard-type-btn.is-btn-active {
  color: #fff;
  background: var(--type-color);
}

.dashboard-type-btn .feature-icon path {
  fill: var(--type-color);
}

.map-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color);
  border: 0;
  background-color: #fff;
  gap: 0 0.5em;
  padding: 0.35em 0.8em;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.75em;
  font-family: inherit;
  font-size: 12px;
}

.modern-map-btn {
  color: #fff !important;
  /* background: var(--color) !important; */
  background: #000;
  border: 1px solid #5959595a;
  border-radius: 10px !important;
}

.modern-map-btn.is-btn-active {
  color: var(--color) !important;
  background: #fff !important;
}

.modern-map-btn.is-btn-active .white-score-progress svg path {
  stroke: #000000;
}

.modern-map-btn svg path {
  fill: var(--type-color) !important;
}

.modern-map-btn .total-establishments {
  color: var(--type-color) !important;
}

.btn-loading {
  cursor: not-allowed !important;
  opacity: 0.8;
}

.map-back-btn {
  position: absolute;
  left: 0;
  margin-left: 1em;
  cursor: pointer;
  pointer-events: auto;
}

.map-back-btn svg {
  width: 40px;
  height: 40px;
}

.map-back-btn svg rect {
  fill: var(--color);
}

.modern-map-back-btn svg rect {
  fill: #fff;
}

.modern-map-back-btn svg path {
  fill: #000;
}

.map-back-btn-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f5671;
  border: 0;
  background-color: #fff;
  gap: 0 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.map-back-btn-mobile img {
  width: 15px;
}

.map-top-controls-mobile .map-back-btn {
  margin-left: 0;
}

.map-back-btn img {
  width: 40px;
}

.map-back-btn svg {
  border-radius: 50%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  padding: 0;
}

.map-btn svg,
.map-btn img {
  width: 15px;
  height: 15px;
}

.map-btn svg path {
  fill: var(--color);
}

.is-btn-active .feature-icon path {
  fill: #fff;
}

.is-btn-active {
  color: #fff;
  opacity: 1;
}

.is-mobile-btn {
  width: fit-content;
  position: relative;
  z-index: 2;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

/* .is-mobile-btn .btn-name {
  display: none;
} */

.is-btn-active .btn-name {
  display: block;
}

.is-btn-active img {
  filter: invert(100%);
}

.plus-icon {
  border: 1px solid #fff;
  border-radius: 0.5em;
  padding: 0.75em;
  width: fit-content;
}

.attachment-container {
  display: flex;
  gap: 1em;
  align-items: center;
  font-size: 10px;
}

.attachment-container span {
  width: 50%;
}

.compare-map-slider {
  width: 100%;
  height: 100%;
}

.compare-map-slider-row {
  display: flex !important;
  height: 100%;
  flex-direction: column;
}

.compare-map-slider-row > div {
  flex: 1;
}

.slider-map-container {
  width: 100%;
  height: 100%;
}

.slider-map-container .slick-list,
.slider-map-container .slick-track,
.slider-map-container .slick-slide > div:first-child {
  height: 100%;
}

.slick-dots {
  pointer-events: none;
}

.slick-dots li {
  pointer-events: auto;
}

.slider-map-container .slick-dots {
  bottom: 10%;
}

/* Styling each individual dot */
.slick-dots li button {
  background-color: #ddd; /* Color of the dot */
  border: none; /* To remove any borders */
  width: 15px; /* Dot width */
  height: 15px; /* Dot height */
  border-radius: 50%; /* Makes the dot circular */
  transition: background-color 0.3s; /* Optional: Smooth transition effect for color changes */
}

.slick-dots li button:before {
  content: none;
}

/* Styling the active dot */
.slick-dots li.slick-active button {
  background-color: var(--color); /* Color of the active dot */
}

.slick-arrow {
  display: none !important;
}

/* COMPARE MAP LOCATION */
.compare-map-location {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.map-tools {
  position: absolute;
  bottom: 2em;
  right: 16pt;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
}

.map-right-controls {
  margin-left: auto;
  display: flex;
  gap: 0 0.5em;
  position: relative;
}

.map-control-btn svg,
.map-control-btn img {
  width: 15px;
  height: auto;
}

.map-controls-small .map-control-btn img {
  width: 10px;
}

.map-controls-small {
  font-size: 10px;
}

.map-index-indicator {
  display: flex;
  align-items: center;
  gap: 0 0.5em;
  background: #fff;
  padding: 0.25em 0.5em;
  font-size: 10px;
  height: fit-content;
  margin: auto 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.zoom-control {
  position: absolute;
  right: 0;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em 0;
  justify-content: center;
  font-size: 10px;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.75em;
  background: #fff;
  filter: drop-shadow(0px 0px 5.75px rgba(0, 0, 0, 0.25));
}

.map-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 5.75px rgba(0, 0, 0, 0.25));
  border-radius: 0.75em;
  color: #4f4f4f;
  font-weight: 800;
}

.zoom-control,
.map-control-wrapper {
  flex-direction: column;
  pointer-events: auto;
}

.map-control-wrapper > * {
  background: #000 !important;
  border-radius: 5px !important;
  margin-bottom: 0.5em;
}

.map-control-wrapper .quick-search-btn {
  margin-bottom: 4pt;
}

.map-control-wrapper .change-view-btn {
  margin-bottom: 0.5em;
}

.map-control-wrapper .zoom-control-wrapper {
  margin-bottom: 0.25em;
}

.modern-map-control-wrapper .map-control-btn {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
}

.modern-map-control-wrapper .map-control-btn:not(.quick-search-btn) svg path,
.modern-zoom-control .map-control-btn svg path {
  fill: #fff;
  stroke: #1e1d1d;
}

.modern-map-control-wrapper .fullscreen-map svg path {
  stroke: #fff;
  fill: none;
}

.modern-map-control-wrapper .map-control-btn svg rect {
  fill: #fff;
}

.modern-zoom-control > *:not(:last-child) {
  border-bottom: 2px solid #1e1d1d !important;
  border-bottom: 2px solid #1e1d1d !important;
}

.modern-zoom-control > * {
  padding: 0.5em !important;
}

.modern-zoom-control > * {
  padding: 0.3em 0.5em !important;
}

.modern-lifestyle-zoom-control .map-control-btn svg,
.modern-lifestyle-map-control-wrapper .map-control-btn svg {
  width: 10px;
}

.modern-zoom-control .map-control-btn svg rect {
  fill: #fff;
}

.map-control-rating {
  padding: 0 0.75em;
  width: auto !important;
}

.map-control-active {
  background: #d9d9d9;
}

.zoom-control > * {
  padding: 0.5em 0.75em;
}

.zoom-control > *:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

.establishment-count,
.map-filter-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  background: #0f5671;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 10px;
  color: #fff;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 1em;
}

.establishment-count-small,
.map-filter-btn-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  background: #0f5671;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 10px;
  color: #fff;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 1em;
}

.establishment-count:hover,
.map-filter-btn:hover {
  border: 2px solid #fff;
}

.is-map-btn-active {
  border: 2px solid #fff;
}

.establishment-count-small img,
.map-filter-btn-small img {
  width: 12.5px;
}

.establishment-number {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

/* EMPTY MAP CONTAINER */
.empty-map-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border: 1px solid #999;
}

.modern-empty-map-container {
  flex-direction: column;
  color: #8a8a8a;
  background-color: #fff;
  font-size: 14px;
}

.empty-input {
  width: 50%;
}

.compare-address-number {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 30px;
  height: 30px;
  background: #fff;
  font-size: 10px;
}

.mobile-map-controls {
  position: fixed;
  margin-top: 5em;
  top: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

/* .gm-tilt {
  display: none !important;
} */

.gmnoprint {
  visibility: hidden;
}

.gmnoprint > div {
  display: none;
}

.gmnoprint.gm-bundled-control {
  bottom: 126px !important;
}

.gmnoprint.gm-bundled-control > div > div {
  /* background-color: unset !important; */
}

.gm-svpc {
  top: -90px !important;
  border-radius: 10px !important;
  scale: 0.8 !important;
}

.gm-iv-small-container {
  margin: var(--svmt) 0 0 1.5em !important;
  border-radius: 50% !important;
  height: 40px !important;
  width: 40px !important;
}

.gm-iv-close {
  width: inherit !important;
  height: inherit !important;
  scale: 1.2 !important;
}

.map-control-btn-popup {
  z-index: 10000;
  background-color: #fff !important;
  width: max-content !important;
  color: var(--color) !important;
  opacity: 1 !important;
  margin-bottom: 0 !important;
  padding: 2px 10px !important;
  font-size: 12px !important;
}

.establishment-distance {
  display: flex;
  align-items: center;
  gap: 0 0.5em;
}

.distance-rating-container {
  display: flex;
  align-items: center;
  gap: 0 0.5em;
}

.fixed-height-buttons-container {
  height: 35.59px !important;
}

.compare-fixed-height-buttons-container {
  height: 29.59px !important;
}

.street-view-toggle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.4em;
  bottom: 160px;
  right: 0;
  background: #fff;
  color: #fff;
  border-radius: 0.75em;
  filter: drop-shadow(0px 0px 5.75px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.close-street-view {
  position: absolute;
  display: flex;
  z-index: 1;
  top: 11px;
  margin-left: 1em;
  padding: 0.466em;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.compare-close-street-view {
  top: 135px;
}

.close-street-view img {
  width: 25px;
  height: auto;
}

.hide-component {
  visibility: hidden;
}

.small-map-btn {
  font-size: 0.7em;
}

.feature-buttons {
  /* width: 90%; */
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  justify-content: end;
  z-index: 1;
}

.establishment-back-btn {
  margin-top: 2rem;
}

.quick-search-results {
  position: absolute;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #fff;
}

.quick-search-results p {
  color: #000;
  font-size: small;
  margin: 0;
}

.marker-details-container {
  scale: 1;
  max-height: 600px;
  width: 450px;
}

.marker-details-card {
  width: 100%;
  /* padding: 1rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: inherit;
  color: var(--color);
}

.marker-details-card .skeleton-main-container {
  align-items: center;
}

.marker-details-card .skeleton-main-container .loading-marker-img {
  width: 120px !important;
  height: 120px !important;
}

.marker-details-card .skeleton-main-container .loading-marker-details {
  margin: 1rem;
}

.marker-details-title {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
}

.marker-details-name-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  width: 80%;
}

/* .marker-details-category svg path {
  fill: var(--color);
} */

.marker-details-gem {
  position: relative;
  height: 25px;
  margin-left: 0.2rem;
}

.marker-details-gem-icon {
  height: 25px;
  width: auto;
  fill: var(--gem-bg);
}

.marker-details-gem-icon path {
  stroke: var(--gem-bg);
}

.category-image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4px;
  height: 12px;
  width: auto;
}

.category-image path {
  fill: #fff;
}

.marker-details-img {
  object-fit: cover;
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
}

.marker-details {
  margin-top: 0.6rem;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

.marker-rating-container,
.marker-details-item {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: left;
}

.marker-details-item > .marker-details-distance {
  position: relative;
  height: 45px;
  width: 45px;
  font-weight: bold;
}

.marker-details-distance > .marker-stat-progress {
  height: 45px;
}

.marker-details-distance > .marker-stat-progress svg {
  height: 45px;
  width: 45px;
}

.marker-details-distance > .marker-stat-progress > .marker-stat-value {
  font-size: 10px;
}

.marker-details-distance > .marker-stat-progress svg circle {
  stroke: #000000;
}

.marker-rating-container {
  padding: 0;
  margin-left: 0.5rem;
}

.react-simple-star-rating .empty-icons,
.react-simple-star-rating .filled-icons {
  display: -webkit-box !important;
}

.marker-details-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: auto;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.marker-details-close path {
  fill: var(--color);
}

.marker-types {
  flex-wrap: wrap;
}

.marker-types div {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.marker-types div svg path {
  stroke: #268844;
}

.marker-details-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 1.5rem 1rem 0.5rem;
  background: #ffffff;
}

.marker-details-tabs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 0.3rem;
}

.marker-details-tabs button {
  width: 80%;
  border: none;
  border-bottom: 1px solid transparent;
  background: none;
  color: #70757a;
  font-size: 14px;
  font-weight: 600;
  padding: 0.6rem;
  margin: 0 auto;
  cursor: pointer;
  transition: border-bottom 0.5s ease;
}

.selected-marker-details-tab {
  color: var(--color) !important;
  border-bottom: 1px solid var(--color) !important;
}

.marker-address-name {
  display: inline;
  gap: 0.25em;
  text-align: left;
  font-size: 18px;
  font-weight: 800;
  word-break: break-word;
}

.marker-details-item svg {
  height: 14px;
  width: 14px;
}

.marker-details-item .marker-details-icon path {
  fill: #000;
}

.marker-details-icon path {
  fill: #fff;
}

.clock-icon path {
  fill: none !important;
  stroke: var(--color);
}

.add-to-favorites-container button {
  display: flex;
  align-items: center;
  color: var(--color);
  background: none;
  border: 1.5px solid var(--color);
  border-radius: 10px;
  margin: 0.5rem auto 0 auto;
  padding: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  font-family: inherit;
}

.add-to-favorites-container button svg {
  width: 20px;
  height: auto;
}

.marker-details-header .buttons {
  position: absolute;
  right: 1em;
  top: 2.5em;
}

.compare-address-title-container {
  display: flex;
  align-items: center;
  gap: 0.25em 1em;
}

.compare-address-title {
  width: 330px;
  max-width: 330px;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #8D8D8D;
  color: #fff;
  text-transform: uppercase;
  border-radius: 10px;
  pointer-events: auto;
  background: #000000;
}

.remove-icon {
  height: 20px !important;
  width: auto;
}

.add-to-favorites-icon path:nth-child(1) {
  fill: none;
}

.add-to-favorites-icon path:nth-child(2) {
  fill: #fff;
  stroke: none;
}

.added-to-favorites path:nth-child(2) {
  fill: #eb082a !important;
}

.buttons .add-to-favorites-icon path:nth-child(2),
.marker-item.selected .add-to-favorites-icon path:nth-child(2) {
  fill: rgb(217, 217, 217);
  stroke: none;
}

.marker-reviews {
  max-height: 145px;
  overflow-y: auto;
}

.modern-lifestyle-compare-popup .marker-reviews {
  max-height: 100px;
}

.marker-reviews-container {
  background: #fff;
  padding: 0.5rem 1rem 1rem 1rem;
}

.review-container-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.marker-reviews::-webkit-scrollbar {
  width: 10px;
}

.marker-reviews::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.marker-reviews::-webkit-scrollbar-track {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.review-item {
  display: flex;
  flex-direction: column;
  padding: 0.7rem 0.5rem;
}

.review-item:not(:first-child) {
  border-top: 1px solid #dadce0;
}

.review-header {
  display: flex;
  gap: 0.5rem;
}

.review-header > div {
  display: flex;
  gap: 0.5rem;
}

.reviewer-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.reviewer-details {
  display: block;
}

.reviewer-name {
  max-width: 200px;
  font-size: 14px;
  font-weight: 600;
  overflow-wrap: break-word;
}

.review-date {
  font-size: 12px;
  color: #afb1b5;
}

.review-content {
  margin-top: 0.2rem;
  text-align: justify;
  font-size: 12px;
}

.no-reviews {
  margin: 0.8rem 0;
  text-align: center;
}

.marker-stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  background: rgba(13, 13, 13, 0.85);
  color: #fff;
  padding: 1rem 0;
  /* margin-top: 0.3rem; */
}

.marker-stats-container .slick-slider {
  width: 100%;
}

.marker-stats-container .slick-slider .slick-prev {
  left: 25px;
  z-index: 1;
}

.marker-stats-container .slick-slider .slick-next {
  right: 25px;
}

.hide-prev-arrow .slick-slider .slick-prev {
  display: none !important;
}

.hide-next-arrow .slick-slider .slick-next {
  display: none !important;
}

.stats-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.marker-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(0.9);
}

.marker-stat-progress {
  position: relative;
  height: 75px;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  transform: scale(0.9);
}

.stat-progress-value {
  position: absolute;
}

.stat-progress-value circle {
  stroke: #fff;
}

.stat-progress-value circle {
  stroke-dasharray: 251.2;
  stroke-dashoffset: calc(251.2 - (251.2 * var(--percent) / var(--total)));
  transition: stroke-dashoffset 0.3s ease;
  transform: rotate(-180deg) scaleX(-1);
  transform-origin: 50% 50%;
}

.marker-stat-item svg {
  height: 75px;
  width: 75px;
}

.marker-stat-value {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marker-stat-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 12px;
  font-weight: bold;
}

.marker-stat-label svg {
  height: 18px;
  width: auto;
}

.marker-stat-label > div {
  display: flex;
}

.co2-reduced-icon {
  height: 14px !important;
}

.subset-text {
  font-size: 7px;
  margin-top: auto;
}

.max-distance {
  border-top: 1px solid #fff;
  margin-top: 0.2rem;
  padding-top: 0.2rem;
}

.blur-marker img {
  filter: blur(5px);
}

.lifestyle-compare-map-header {
  height: 45px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 0.5rem;
  color: #fff;
  background: #000000a6;
  padding: 0 0.5rem;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  z-index: 1;
}

.lifestyle-compare-map-header .house-icon-cotainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lifestyle-compare-map-header .house-icon-cotainer svg {
  width: 35px;
}

.lifestyle-compare-map-header .modern-address-sublocality {
  color: #fff;
  margin-top: 1rem;
}

.lifestyle-compare-map-header .score-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.lifestyle-compare-map-header .score-icon-v2 span {
  top: 50%;
}

.lifestyle-compare-map-header .score-icon-v2.gem span {
  top: 47.5%;
}

.view-map-right-container {
  display: flex;
  gap: 1em;
  margin-left: auto;
}

.compare-map-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8pt;
  padding: 0.5rem;
}

.compare-map-footer .view-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  font-size: 10px;
  padding: 0.5rem;
  cursor: pointer;
  pointer-events: auto;
  text-transform: uppercase;
}

.lifestly-compare-combine-search {
  flex-direction: row-reverse;
}

.lifestly-compare-map-footer {
  position: absolute;
  bottom: 0;
  height: 45px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem 0.3rem 0.5rem 1rem;
}

.type-scores {
  display: flex;
}

.lifestyle-search-score-container {
  display: flex;
  align-items: center;
  gap: 0 0.35rem;
}

.lifestyle-search-score-container svg {
  height: 15px;
  width: 15px;
}

.lifestyle-search-score-container svg path {
  fill: #fff;
}

.lifestly-compare-map-footer
  .lifestyle-search-score-container
  .tab-score-desciption {
  font-size: 10px;
}

.lifestly-compare-map-footer
  .lifestyle-search-score-container
  .modern-custom-button-icon {
  margin: 0;
}

.lifestly-compare-map-footer
  .lifestyle-search-score-container.gem
  .modern-custom-button-icon {
    height: 40px;
    width: 40px;
  }

.lifestyle-search-score-container
  .modern-custom-button-icon
  .score-diamond
  svg {
  width: 40px;
  height: 40px;
}

.lifestly-compare-map-footer .modern-lifestyle-custom-button {
  width: 27px;
  height: 27px;
}

.lifestly-compare-map-footer .property-score-container {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.lifestly-compare-map-footer .property-score-container .gem-score-container {
  width: auto;
  text-align: center;
  font-size: 10px;
}

.lifestly-compare-map-footer .property-score-container .gem-score-container hr {
  margin: 0.1rem 0;
}

.lifestly-compare-map-footer
  .property-score-container
  .modern-custom-button-icon {
  width: 40px;
  height: 40px;
  margin: 0;
}

.lifestly-compare-map-footer
  .property-score-container
  .modern-lifestyle-custom-button
  .score-diamond {
  top: 0;
}

.lifestly-compare-map-footer .type-scores .modern-dashboard-type-btn {
  z-index: 0;
}

.lifestly-compare-map-footer
  .type-scores
  .modern-dashboard-type-btn
  .modern-lifestyle-custom-button
  .score-diamond {
  top: 2px;
}

.lifestly-compare-map-footer
  .property-score-container
  .modern-lifestyle-custom-button
  > * {
  margin-bottom: 3px;
}

.gem-feature-score {
  color: var(--tab-color);
  font-weight: 400;
}

.lifestly-compare-map-footer .modern-dashboard-type-btn {
  min-width: unset;
}

.lifestly-compare-map-footer .gem-type-scores .modern-dashboard-type-btn {
  padding: 0 0.5em;
}

.autocomplete-wrapper .add-icon,
.autocomplete-wrapper .clear-icon {
  width: 18.5px;
  height: 18.5px;
  cursor: pointer;
}

.autocomplete-wrapper .clear-icon {
  transform: rotate(45deg);
}

.autocomplete-wrapper .clear-icon path {
  fill: #bb241e;
}

.filter-result-container {
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 14px;
}

.compare-filter-result-container {
  bottom: 5rem;
}

.lifestly-compare-map-footer .gem-count {
  font-size: 8px;
}

.lifestly-compare-map-footer
.modern-custom-button-icon >
.score-diamond
svg {
  width: 40px;
  height: 40px;
}

.map-address-sublocality {
  position: absolute;
  left: 1rem;
  top: 1rem;
  background: #31a5f9;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
  font-size: 12px;
  z-index: 1;
}

.maps-container .map-controls {
  width: 100%;
  width: -webkit-fill-available;
  height: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 4;
  padding: 16pt;
  pointer-events: none;
}

.maps-container .compare-map-controls {
  background: #262626;
}

.map-controls .neighborhood-switch {
  display: flex;
  justify-content: center;
  gap: 0 1rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.map-controls .neighborhood-switch div {
  border-bottom: 1px solid transparent;
  padding: 0.5rem 1rem;
  pointer-events: auto;
  transition: all 0.2s ease;
}

.map-controls .neighborhood-switch .active {
  border-bottom: 1px solid #fff;
  font-weight: 500;
}

.map-controls > div:not(.neighborhood-switch) {
  padding: 16pt;
}

.maps-container .address-btns {
  position: absolute;
  width: 100%;
  bottom: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.4rem 0;
}

.maps-container .address-btns div {
  display: flex;
  margin: 0 auto;
}

.maps-container .address-btns svg {
  margin-right: 8pt;
}

.maps-container .address-btns .add path {
  fill: #fff;
}

.maps-container .address-btns div > * {
  height: fit-content;
  color: #888888;
  background: #211F1F;
  padding: 0.2rem 0.7rem;
  font-size: 14px;
  pointer-events: auto;
}

.maps-container .address-btns .current-map {
  background: #000;
  color: #fff;
}

.map-controls .map-top-controls {
  margin: 0;
  position: unset;
  padding: 0 0 16pt !important;
}

.map-controls .feature-buttons div {
  pointer-events: auto;
}

.map-controls .feature-buttons .gem-content {
  margin-bottom: 0.2rem;
}

.map-controls .score-icon-container {
  margin: 0;
}

.quick-search-btn,
.map-controls .filter-btn {
  width: 35px;
  height: 35px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  pointer-events: auto;
}

.quick-search-btn.open,
.map-controls .filter-btn.open {
  background: #fff !important;
}

.map-controls .filter-btns .filter-btn svg path {
  fill: #fff;
}

.map-controls .filter-btn.open svg path {
  fill: #000;
}

.quick-search-btn.open svg path:first-child {
  fill: #000;
}

.quick-search-btn.open svg path:nth-child(2),
.quick-search-btn.open svg path:nth-child(3),
.quick-search-btn.open svg path:nth-child(4) {
  stroke: #000;
}

.map-controls .filter-wd {
  height: calc(100% - 46pt);
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

.map-controls .compare-filter-wd {
  justify-content: end;
}

.map-controls .filter-wd .mobile-walking-distance-wrapper,
.map-controls .filter-wd .filter-btns {
  display: flex;
  flex-direction: column;
  gap: 6pt;
}

.map-controls .filter-wd .mobile-walking-distance-wrapper {
  margin-left: auto;
  align-items: center;
  padding-left: 16pt;
}

.map-controls .filter-wd .filter-btns svg {
  height: 20px;
  width: auto;
}

.map-controls .filter-wd .distance-btn {
  position: relative;
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: #000;
  color: #fff;
  border: 1px solid #1e1d1d;
  border-radius: 50%;
  pointer-events: auto;
  line-height: normal;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 0 auto;
}

.map-controls .filter-wd .distance-btn.small {
  width: 30px;
  height: 30px;
}

.map-controls .filter-wd .distance-btn.medium {
  width: 40px;
  height: 40px;
}

.map-controls .filter-wd .apx-time {
  width: max-content;
  max-width: 50px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
}

.map-controls .filter-wd .active {
  background: #fff;
  border: 1px solid transparent;
  color: #585858;
  font-weight: 700;
}

.map-controls .filter-wd .distance-btn svg path {
  fill: #ffffff;
}

.map-controls .filter-wd .active svg path {
  fill: #585858;
}

.map-controls .filter-wd .distance-btn .line {
  position: absolute;
  background-color: #ffffff;
}

.map-controls .filter-wd .distance-btn.active .line {
  background-color: #000000;
}

.map-controls .filter-wd .distance-btn.small .line {
  width: 14px;
  height: 1px;
  left: -1px;
  bottom: 8.5px;
}

.map-controls .filter-wd .distance-btn.medium .line {
  width: 16px;
  height: 1px;
  left: 0px;
  bottom: 14px;
}

.map-controls .filter-wd .distance-btn.large .line {
  width: 19px;
  height: 1px;
  left: 0px;
  bottom: 15.5px;
}

.maps-container .gradient-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.88) 100%);
  margin: 0 !important;
  z-index: 0;
}

.maps-container .map-popups-wrapper {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  gap: 8pt;
  z-index: 0;
}

.maps-container .map-popups-wrapper > * {
  pointer-events: auto;
}

.maps-container .capsule-map-btn .feature-icon {
  height: 7px;
  width: auto;
}

.maps-container .capsule-map-btn .feature-icon-wrapper {
  display: flex;
  padding: 5pt 0;
}

.maps-container .capsule-map-btn .feature-icon-wrapper.compare svg {
  height: 10px;
}

.map-autocomplete {
  width: 100%;
  max-width: 330px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background: #ffffff;
  border: 2px solid #3D3D3D;
  border-radius: 10px;
  pointer-events: auto;
}

.map-autocomplete input {
  flex-grow: 1;
  background: transparent;
  border: none;
  font-size: 14px;
}

.map-autocomplete input:focus {
  outline: none;
  border: none;
}

.map-autocomplete .prediction-container {
  width: calc(100% - 1.5px);
}

.map-controls .show-compare-icon path {
  fill: #ffffff;
}

.map-top-controls > div:first-child {
  display: flex;
  align-items: center;
  gap: 8pt;
  flex-grow: 1;
  z-index: 1;
}

.map-top-controls > div:first-child svg {
  cursor: pointer;
  pointer-events: auto;
}

.map-top-controls .map-top-icons {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.map-top-controls .map-top-icons > * {
  width: 18px;
  height: 18px;
}

.map-top-back rect { 
  fill: #000;
}

.marker-list-wrapper .marker-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8pt;
  padding: 12pt 16pt;
  color: #ffffff;
  background: #0D0D0D80;
  border-bottom: 1px solid #232323;
}

.marker-list-wrapper .no-result {
  justify-content: center;
  color: #919191;
  border: none;
}

.marker-list-wrapper .marker-details-gem {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.marker-list-wrapper .favorite-icon {
  height: 16px;
  width: auto;
  margin: 0 0 0 auto;
  cursor: pointer;
}

.marker-list-wrapper .marker-details-gem-icon {
  height: 16px;
}

.marker-list-wrapper .category-image {
  height: 8px;
  top: 3px;
}

.marker-list-wrapper .category-image path {
  fill: #fff;
}

.marker-list-wrapper .marker-address-name {
  font-size: 16px;
  font-weight: 500;
}

.marker-list-wrapper .popup-category-container > * {
  background: #fff;
  color: #000;
  font-weight: 500;
  border-radius: 1px;
}

.marker-list-wrapper .marker-other-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2pt;
  font-size: 12px;
}

.marker-list-wrapper .marker-other-details  > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.marker-list-wrapper .marker-item.selected {
  color: #000000;
  background: #ffffff;
}

.marker-list-wrapper .marker-other-details svg {
  height: 14px;
  width: auto;
}

.marker-list-wrapper .marker-other-details svg:not(.star-svg) path:not(:nth-child(2)) {
  fill: #fff;
}

.marker-list-wrapper .marker-item.selected .category-image path {
  fill: #ffffff;
}

.marker-list-wrapper .marker-item .remove-icon  {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.marker-list-wrapper .marker-item.selected .remove-icon path {
  fill: #000;
  stroke: #000;
}

.marker-list-wrapper
.marker-item.selected
.marker-other-details
svg:not(.star-svg)
path:not(:nth-child(2)) {
  fill: #000000;
}

.marker-list-wrapper
.marker-item.selected
.marker-other-details
svg:not(.star-svg)
path:not(:nth-child(1)) {
  fill: #ffffff;
}

.marker-list-wrapper .marker-other-details .distance {
  margin-left: auto;
}

.map-popups-wrapper .map-marker-list {
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8pt 0;
  color: #ffffff;
  background: #0D0D0DD9;
  border-radius: 10px;
  overflow-y: auto;
  height: 100%;
}

.map-popups-wrapper .map-marker-list-header {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
  gap: 0 6pt;
  padding: 16pt 16pt 0 16pt;
}

.map-popups-wrapper .map-marker-list-header .close-icon {
  margin-left: auto;
}

.map-popups-wrapper .map-marker-list-header svg:not(.close-icon) path {
  fill: var(--tab-color);
}

.address-list-wrapper .address-item {
  display: flex;
  flex-direction: column;
  gap: 0.3rem 0;
  background: rgba(13, 13, 13, 0.5);
  border-bottom: 1px solid #1C1C1C;
  padding: 1rem;
}


.address-list-wrapper .address-header,
.address-list-wrapper .address-details {
  display: flex;
  justify-content: space-between;
  gap: 0 0.5rem;
}

.address-list-wrapper .address-header div {
  font-size: 18px;
}

.address-list-wrapper .address-details {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.address-list-wrapper .address-details  div {
  display: flex;
  align-items: center;
  gap: 0 4pt;
}

.address-list-wrapper .other-details {
  color: #D4D4D4;
  font-size: 10px;
  margin-left: calc(18px + 4pt);
}

.address-item svg, .marker-item  svg {
  height: 18px;
  width: auto;
}

.address-list-wrapper .address-item .delete-icon {
  cursor: pointer;
}

.openstreetmap-wrapper {
  position: absolute;
  bottom: 0;
  right: 80pt;
  color: #000;
  background: #fff;
  font-size: 10px;
  padding: 1pt 2pt;
}

@media screen and (min-width: 1440px) {
  .modern-lifestyle-compare-popup {
    transform: scale(1) translate(50%, 50%);
  }
}


@media screen and (min-width: 1024px) {
  .lifestly-compare-map-footer
    .property-score-container
    .modern-custom-button-icon {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  .lifestly-compare-map-footer
    .property-score-container
    .modern-lifestyle-custom-button {
    width: 40px;
    height: 40px;
  }

  .lifestly-compare-map-footer .type-scores .modern-dashboard-type-btn {
    font-size: 10px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-lifestyle-custom-button,
  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-lifestyle-custom-button
    .score-diamond
    svg {
    height: 30px !important;
    width: 30px !important;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-custom-button-icon {
    height: 25px;
    width: 25px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-custom-button-icon
    svg {
    height: 12px;
    width: 12px;
  }

  .feature-buttons {
    max-width: unset;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modern-dashboard-type-btn {
    font-size: 10px;
  }

  .modern-custom-button-icon {
    height: 25px;
    width: 25px;
  }

  .modern-lifestyle-custom-button {
    width: 30px;
    height: 30px;
  }

  .dashboard-type-btn svg,
  .map-btn svg,
  .map-btn img {
    width: 12px;
    height: 12px;
  }

  .modern-custom-button-icon > .score-diamond svg {
    width: 30px;
    height: 30px;
  }

  .lifestly-compare-map-footer .modern-lifestyle-custom-button {
    width: 30px;
    height: 30px;
  }

  .lifestly-compare-map-footer
    .property-score-container
    .modern-custom-button-icon {
    width: 40px;
    height: 40px;
  }

  .lifestly-compare-map-footer
    .property-score-container
    .modern-custom-button-icon
    .score-diamond
    svg {
    width: 40px;
    height: 40px;
  }

  .modern-popup {
    scale: 0.85;
  }

  .modern-lifestyle-compare-popup {
    right: -4.5em;
    bottom: -1.5em;
    transform: scale(0.55);
  }
}

@media screen and (max-width: 768px) {
  .map-top-controls {
    justify-content: flex-start;
    margin: 1em 0;
  }

  .map-top-controls .feature-buttons {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    gap: 3pt;
  }

  .marker-rating-container {
    font-size: 12px;
  }

  .map-top-controls .feature-buttons::-webkit-scrollbar {
    display: none;
  }

  .map-top-controls.gray-bg {
    background: #211f1f;
  }

  .map-controls > div:not(.neighborhood-switch) {
    margin: 8px 10px;
    padding: 0;
  }

  .map-controls .filter-wd {
    position: relative;
    justify-content: space-between;
    margin: 0 !important;
    padding: 10px !important;
  }

  .map-controls .filter-wd.black-bg {
    background: #100f0f;
  }

  .map-controls .filter-wd .graph-wrapper {
    position: absolute;
    width: calc(100% - 20px);
    color: #ffff;
  }

  .map-back-btn {
    top: 25px;
  }

  .map-btn {
    padding: 0.5em;
  }

  .map-btn-wrapper {
    display: flex;
    align-items: center;
    background: #fff;
    outline: none;
    border: 0.8em solid #fff;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin: -0.8em;
    z-index: 1;
  }

  .map-btn-wrapper .close-toggle {
    height: 20px;
    width: auto;
    margin-left: 0.5em;
    transform: rotate(180deg);
  }

  .map-filter-btn,
  .establishment-count {
    width: 40px;
    height: 40px;
  }

  .map-filter-btn span,
  .establishment-count span {
    display: none;
  }

  .map-filter-btn img,
  .establishment-count img {
    width: 20px;
  }

  .map-tools {
    bottom: 66px;
  }

  .compare-map-slider .map-tools {
    bottom: 0;
  }

  .compare-map-slider .modern-lifestyle-map-controls {
    bottom: 10%;
  }

  .map-control-wrapper > *:not(:last-child) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-control-btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-control-btn img {
    width: 15px !important;
  }

  .empty-map-container {
    flex-direction: column;
  }

  .slider-map-container .slick-dots {
    top: 10%;
    bottom: 85%;
  }

  .modern-slider-map-container .slick-dots {
    display: none !important;
  }

  .iphone-slider-map-container .slick-dots {
    top: unset;
    bottom: calc(100dvh - 91dvh);
    z-index: 5;
  }

  .street-view-toggle {
    padding: 0.7em 0.75em;
    bottom: 175px;
  }

  .close-street-view {
    top: 25px !important;
    right: 11px;
    margin-top: 0;
  }

  .gm-svpc {
    top: -205px !important;
    left: 2px !important;
  }

  .gmnoprint.gm-bundled-control {
    bottom: 70px !important;
  }

  .quick-search-results {
    top: 0;
    right: 0;
    margin: 1.7rem 1rem 0 0;
  }

  .empty-input input,
  .location-select {
    font-size: 16px !important;
  }

  .quick-search-map-controls {
    bottom: 15px !important;
  }

  .popup {
    width: 350px;
  }

  .marker-details-gem,
  .marker-details-gem-icon {
    height: 20px;
  }

  .marker-details-close {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  .marker-address-name {
    max-width: 200px;
    font-size: 16px;
    font-weight: 500;
  }

  .marker-details-item {
    font-size: 12px;
  }

  .marker-stats-container {
    background: #fff;
    color: #000;
    padding: 0;
    margin: 16pt 0;
  }

  .lifestly-compare-map-footer {
    bottom: 0;
    height: 75px;
    align-items: end;
  }

  .modern-custom-button-icon {
    height: 40px;
    width: 40px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-custom-button-icon {
    width: 20px;
    height: 20px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-custom-button-icon
    svg {
    height: 10px;
    width: 10px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .btn-name {
    font-size: 8px;
  }

  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-lifestyle-custom-button,
  .lifestly-compare-map-footer
    .type-scores
    .modern-dashboard-type-btn
    .modern-lifestyle-custom-button
    .score-diamond
    svg {
    height: 25px !important;
    width: 25px !important;
  }

  .lifestly-compare-map-footer
    .lifestyle-search-score-container
    .tab-score-desciption {
    font-size: 9px;
  }

  .establishment-back-btn {
    margin-top: 0;
  }

  .mobile-map-score-controls {
    height: auto;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    position: absolute;
    bottom: 0;
    left: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: #000000;
    padding: 10px;
    z-index: 5;
  }

  .mobile-map-score-controls .score-container {
    display: flex;
    align-items: center;
    justify-content: end;
    font-weight: 300;
    color: #ffffff;
  }

  .mobile-map-score-controls .current-score {
    color: #fff;
    font-weight: 600 !important;
  }

  .mobile-map-score-controls .current-score img {
    height: 30px;
  }

  .mobile-map-score-controls
  .score-container
  .white-score-progress
  svg
  path {
    stroke: #ffffff;
  }

  .mobile-map-score-controls
  .current-score
  .white-score-progress
  svg
  path {
    stroke: #fff;
    stroke-width: 20px !important;
  }

  .mobile-map-score-controls .score-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff !important;
  }

  .mobile-map-score-controls .score-description .type {
    font-size: 16px;
  }

  .mobile-map-score-controls .score-description .value {
    font-size: 10px;
  }

  .mobile-map-score-controls .popup-buttons {
    display: flex;
    gap: 0 0.5rem;
    margin: auto 0 0.5rem;
  }

  .mobile-map-score-controls .popup-buttons.no-address {
    width: 100%;
    justify-content: center;
  }

  .mobile-map-score-controls .popup-buttons div {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.1rem 0;
    font-size: 10px;
  }

  .mobile-map-score-controls .skeleton-main-container {
    width: auto;
  }

  .mobile-map-score-controls
  .popup-buttons
  div
  svg {
    height: 20px;
    width: auto;
  }

  .mobile-map-score-controls
  .popup-buttons
  div
  svg path {
    fill: #fff;
  }

  .mobile-map-score-controls
  .popup-buttons
  div
  .grid-icon path {
    fill: unset !important;
    stroke: #fff;
  }

  .mobile-map-score-controls .lifestly-compare-map-footer {
    left: 0;
    height: auto;
  }

  .mobile-map-score-controls .popup-buttons .total-gems {
    font-size: 16px;
    line-height: 20px;
  }

  .mobile-map-score-controls .slider-arrow-container {
    width: -webkit-fill-available;
    position: absolute;
    left: 0;
    top: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .mobile-map-score-controls .align-right {
    justify-content: end;
  }

  .mobile-map-score-controls .align-left {
    justify-content: flex-start;
  }

  .mobile-map-score-controls .previous-btn,
  .mobile-map-score-controls .next-btn {
    display: flex;
    align-items: flex-start;
    gap: 0 2px;
    font-size: 18px;
  }

  .mobile-map-score-controls .previous-btn svg,
  .mobile-map-score-controls .next-btn svg {
    width: 25px;
    height: 25px;
  }

  .mobile-map-score-controls .next-btn svg {
    transform: rotate(180deg);
  }

  .mobile-map-score-controls .gem-type-scores {
    width: 100%;
    gap: 0 1.2rem;
  }

  .mobile-map-score-controls
  .gem-type-scores
  .modern-custom-button-icon {
    height: 30px;
    width: 30px;
  }

  .mobile-map-score-controls .btn-name {
    font-size: 10px;
  }

  .mobile-map-score-controls
  .modern-lifestyle-custom-button
  .feature-icon {
    width: 10px;
    height: 11px;
  }

  .mobile-map-score-controls .loading,
  .mobile-map-score-controls .no-data {
    margin: auto 0 0.5rem;
    font-size: 12px;
  }

  .mobile-map-score-controls .gem-score-container {
    font-size: 16px;
    line-height: normal;
  }

  .mobile-map-score-controls .score-icon-container {
    margin: 0;
  }

  .mobile-map-score-controls .score-icon-container .score-content {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
  }

  .mobile-map-score-controls
  .current-score
  .score-icon-container
  .score-content {
    color: #fff;
  }

  .compare-score-controls .lifestyle-search-score-container {
    margin: auto 0 0.5rem;
  }

  .mobile-controls-popup {
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .mobile-controls-card {
    max-width: 300px;
    position: relative;
    top: calc(0dvh + 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background: #000;
    border-radius: 10px;
    margin: auto 5rem;
    padding: 3rem 1rem;
  }

  .mobile-controls-card .mobile-controls-close-btn {
    width: 20px;
    height: auto;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .mobile-controls-card div {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #3E3E3E;
    padding: 0.5rem;
  }

  .map-mobile-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem 0;
    background: #000;
    padding: 1rem;
  }

  .map-mobile-header-container .controls {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0 1rem;
  }

  .map-mobile-header-container .compare-address {
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }

  .map-mobile-header-container .back-btn {
    height: 35px;
  }

  .map-mobile-header-container .back-btn circle {
    fill: #000;
  }

  .map-mobile-header-container .back-btn path {
    fill: #fff;
  }

  .map-mobile-header-container input,
  .map-mobile-header-container .edit-btn {
    height: 30px;
  }

  .map-mobile-header-container .back-btn,
  .map-mobile-header-container .edit-btn {
    width: auto;
    cursor: pointer;
  }

  .map-mobile-header-container .edit-btn path {
    stroke: #fff;
  }

  .map-mobile-header-container .action-btns,
  .map-mobile-header-container .clear-icon {
    width: 30px;
    height: auto;
  }

  .mobile-address-input-wrapper {
    position: relative;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    align-items: center;
    background: #211F1F;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
  }

  .mobile-address-input-wrapper > div {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .mobile-address-input-wrapper input {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    color: #fff;
    background: none;
    border: none;
    border-radius: 3px;
    margin-right: 0.5rem;
    text-align: center;
    outline: none;
    font-size: 16px;
  }

  .mobile-address-input-wrapper svg {
    height: 16px;
    width: 17px;
  }

  .map-mobile-header-container .mobile-switch-buttons button {
    color: #808080;
    background: #000;
    border: 1px solid #808080;
    padding: 0.5rem 1rem;
    font-size: 12px;
    font-weight: 500;
  }

  .map-mobile-header-container .mobile-switch-buttons {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
  }

  .map-mobile-header-container .mobile-switch-buttons .selected {
    color: #000;
    background: #fff;
    border: 1px solid transparent;
  }

  .map-mobile-header-container .mobile-switch-buttons.gem-only {
    grid-template-columns: unset;
  }

  .map-mobile-header-container .search-address-wrapper {
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .map-mobile-header-container
  .search-address-wrapper
  .lifestyle-search-address-input {
    max-width: 280px;
    padding: 0.2rem;
  }

  .map-mobile-header-container
  .clear-icon {
    transform: rotate(45deg);
  }

  .map-mobile-header-container
  .controls
  .action-btns
  path {
    fill: #fff;
  }

  .map-mobile-header-container
  .clear-icon
  path {
    fill: #BE2626;
  }

  .map-mobile-header-container
  .search-address-wrapper
  .search-address-och {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-mobile-header-container
  .search-address-wrapper
  .search-address-och
  .address-num {
    width: 22px;
  }

  .map-mobile-footer {
    min-height: var(--footer-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #211f1f;
    padding: 0 0 2rem;
  }

  .map-mobile-footer .modern-combine-search-container {
    width: 90%;
  }

  .map-mobile-footer .button-container {
    width: 100dvw;
    display: flex;
    justify-content: space-evenly;
    gap: 0 0.5rem;
    padding: 0 0.5rem;
  }

  .map-mobile-footer .button-container button {
    width: 45%;
    background: #000;
    color: #fff;
    border: 1px solid #363636;
    padding: 0.6rem 0;
    font-size: 12px;
  }

  .map-mobile-footer .modern-sidebar-filter-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 1rem;
  }

  .map-mobile-footer .modern-sidebar-filter-buttons div {
    width: auto;
  }

  .map-mobile-footer .category-button-list {
    width: -webkit-fill-available;
    width: -moz-available;
    background: #000;
    justify-content: space-evenly;
    padding: 1rem;
    pointer-events: none;
  }

  .map-mobile-footer .modern-dashboard-type-btn,
  .mobile-map-score-controls .modern-dashboard-type-btn {
    min-width: unset;
  }

  .mobile-map-score-controls .total-gems,
  .mobile-map-score-controls .selected-feel-feature {
    display: flex;
    flex-direction: column;
    gap: 2pt;
    text-align: center;
    color: var(--tab-color);
  }

  .mobile-map-score-controls .tab-score-desciption {
    display: flex;
    align-items: center;
    gap: 2pt;
    color: var(--tab-color);
    font-weight: 500;
    padding: 0.1rem;
  }

  .mobile-map-score-controls .total-gems .gem-count {
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  .mobile-map-score-controls .selected-feel-feature {
    align-items: center;
  }

  .mobile-map-score-controls .feel-feature-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .mobile-map-score-controls .selected-feel-feature svg {
    height: 10px;
    width: auto;
  } */

  .selected-feel-feature svg path {
    fill: var(--tab-color);
  }

  .mobile-map-score-controls .score-legend-wrapper {
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    gap: 4pt;
  }

  .mobile-map-score-controls .neighborhood-score-container {
    margin: 0;
  }

  .mobile-map-score-controls .ns-version3-wrapper {
    margin: auto 0;
  }

  .mobile-map-score-controls
  .neighborhood-score-container
  .version-2-wrapper
  .selected-feel-feature {
    gap: 4pt 0;
    margin-top: auto;
  }

  .mobile-map-score-controls .score-icon-v2 svg {
    width: 40px !important;
  }

  .mobile-map-score-controls .score-icon-v2 span {
    font-size: 16px;
  }

  .mobile-map-score-controls .score-icon-v2 .description {
    margin-top: 0.2rem;
  }

  .mobile-map-score-controls .version-2-wrapper .feature-icon {
    height: 14px;
    width: auto;
    max-width: unset;
  }

  .mobile-map-score-controls .version-2-wrapper .selected-feel-feature .feature-icon {
    height: 40px;
  }

  .mobile-map-score-controls .neighbohood-tab-popup {
    background: none;
  }

  .mobile-map-score-controls .neighbohood-tab-popup .score-wrapper {
    padding: 0;
  }

  .mobile-map-score-controls .neighbohood-tab-popup .map-type-legends {
    margin: 0;
  }

  .mobile-map-score-controls
  .neighbohood-tab-popup
  .legends-container
  div
  div
  span {
    width: 175px;
  }

  .mobile-map-score-controls
  .neighbohood-tab-popup
  .legends-container
  div
  div
  > * {
    font-size: 12px;
    gap: 0;
  }

  .mobile-map-score-controls .neighbohood-tab-popup {
    max-width: unset;
    width: 100%;
  }

  .mobile-map-score-controls.gray-bg {
    background: #211f1f;
  }

  .mobile-map-score-controls .neighborhood-score-container .change-addresses-btn {
    display: flex;
    gap: 0 8pt;
    background: #000000;
    color: #ffffff;
    padding: 7pt 16pt;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .mobile-map-score-controls .neighborhood-score-container .change-addresses-btn svg {
    height: 18px;
    width: auto;
  }

  .active-score-icon-wrapper {
    height: fit-content;
    max-height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active-score-icon-wrapper svg {
    height: 30px;
    width: auto;
  }

  .active-score-icon-wrapper span {
    position: absolute;
    color: #000000;
    font-size: 12px;
  }

  .active-score-icon-wrapper.gem span {
    margin-bottom: 0.3rem;
  }

  .map-mobile-footer
  .category-button-list
  .modern-dashboard-type-btn:first-child
  .modern-lifestyle-custom-button
  .feature-icon {
    font-size: 10px;
  }

  .map-mobile-footer .dashboard-type-btn .feature-icon {
    height: 14px;
  }

  .slick-dots li.slick-active button {
    background-color: #fff;
    border: 1px solid #6F6F6F;
    pointer-events: auto;
  }

  .slick-dots li button {
    background-color: #BABABA;
    border: 1px solid #FFFFFF;
  }

  .hide-feel {
    display: none;
  }

  .maps-container .map-controls {
    height: calc(100% - 55px);
    pointer-events: none;
    padding: 0;
  }

  .map-controls .map-top-controls {
    width: -webkit-fill-available;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    padding: 0 !important;
    pointer-events: auto;
  }

  .marker-stat-item {
    transform: unset;
  }

  .maps-container .capsule-map-btn {
    height: 28px;
    border-radius: 10px !important;
    border: none;
    padding: 0.5rem 0.7rem;
    font-size: 12px;
  }

  .maps-container .active-capsule-map-btn svg:not(.score-icon) path {
    fill: #000 !important;
  }

  .maps-container
  .active-capsule-map-btn
  .white-score-progress
  svg
  path {
    stroke: #000;
  }

  .maps-container .mobile-navigation-controls {
    position: absolute;
    bottom: calc(120px + 10px);
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 3pt 0;
    transition: all 0.5s ease;
  }

  .maps-container .mobile-navigation-controls.show-legend {
    bottom: calc(135px + 10px);
  }

  .maps-container .mobile-navigation-controls.show-legend-feel {
    bottom: calc(135px + 50px);
  }

  .maps-container .mobile-compare-navigation-controls {
    bottom: calc(113.75px + 10px);
  }

  .maps-container .mobile-navigation-controls div {
    height: 32px;
    width: 32px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #1E1D1D;
  }

  .maps-container .mobile-navigation-controls svg {
    height: 18px;
    width: auto;
  }

  .maps-container .mobile-navigation-controls svg path {
    fill: #fff;
  }

  .maps-container .marker-details-card {
    position: absolute;
    bottom: -300px;
    z-index: 2;
    transition: all 0.5s ease;
    background: #fff;
  }

  .marker-details-card.open {
    bottom: 0;
    transform: translateY(0);
  }

  .marker-details-item svg {
    height: 10px;
    width: 10px;
  }

  .marker-stat-progress {
    height: 65px;
  }

  .marker-stat-progress svg {
    height: 65px;
    width: 65px;
  }

  .marker-stat-progress svg circle {
    stroke: #000;
  }

  .marker-stat-value {
    font-size: 12px;
  }

  .stats-container {
    gap: 8pt;
  }

  .marker-stat-label {
    gap: 0.2rem;
    font-weight: 400;
    font-size: 10px;
  }

  .marker-details-header {
    padding: 16pt 1rem 8pt
  }

  .marker-details-tabs button {
    font-size: 10px;
    font-weight: 500;
    padding: 0.2rem;
  }

  .marker-details-icon path {
    fill: #000;
  }

  .marker-stat-label .info-icon circle,
  .marker-stat-label .info-icon path {
    stroke: #000;
  }

  .marker-details-toggle {
    margin: 16pt auto 0;
  }

  .no-reviews {
    font-size: 12px;
  }

  .marker-details-header .buttons {
    position: unset;
    display: flex;
    gap: 0 0.5rem;
    margin-right: unset;
    margin-left: auto;
  }

  .marker-details-header .buttons svg {
    height: 20px;
    width: auto;
  }

  .maps-container .compare-map-controls {
    background: none;
  }

  .map-controls .filter-wd .distance-btn svg {
    height: 11px;
    width: auto;
  }

  .map-controls .compare-map-top-controls {
    margin: 0 !important;
    padding: 8px 10px !important;
  }

  .map-controls .filter-wd .distance-btn svg path {
    fill: #ffffff;
    stroke: #ffffff;
  }

  .map-controls .filter-wd .distance-btn.active svg path {
    fill: #000000;
    stroke: #000000;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
