.landing-home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: var(--font-family);
}

.landing-home-container > * {
  width: 100%;
  box-sizing: border-box;
}

.home-banner,
.home-banner span {
  width: 100%;
}

.home-banner {
  height: calc(100vh - (75px + 0.1rem));
  position: relative;
  overflow: hidden;
}

.home-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home-banner .video-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.banner-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure it's behind other content but in front of the video */
}

.home-banner > div {
  height: inherit;
}

.banner-content {
  position: relative;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem 0;
  color: #fff;
  z-index: 2;
}

.banner-content .slogan {
  font-size: 32px;
}

.banner-content .search-address-input {
  padding: 0 0.5rem 0 0;
  max-width: 500px;
}

.banner-content .current-location-icon {
  height: calc(18px + 1rem);
  display: flex;
  align-items: center;
  background: #f3f3f2;
  border-right: 1px solid #7f7f7f;
  padding: 0 0.5rem;
  cursor: pointer;
}

.banner-content .search-address-input svg path {
  fill: #292d32;
}

.banner-content .current-location-icon svg path {
  fill: #4f4f4f;
}

.banner-content .search-address-input .modern-search-address-btn {
  display: flex;
}

.banner-content .features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 700px; */
  flex-wrap: nowrap;
  background-color: #000000CC;
}

.banner-content .features svg {
  width: 30px;
  height: auto;
}

/* .banner-content .features a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 0 0.5rem;
  cursor: pointer;
  padding: 0 1em;
  text-decoration: none;
  color: #fff;
} */

.banner-content .req-demo {
  background: #0D0D0DCC;
  transition: all 0.3s ease-in;
}

.banner-content .req-demo svg circle,
.banner-content .req-demo svg path {
  stroke: #ffffff;
}

.banner-content .req-demo:hover svg circle,
.banner-content .req-demo:hover svg path {
  stroke: #000000;
}

.home-neighborhood-search {
  padding: 4rem 0;
  color: #fff;
  background: #211f1f;
}

.home-neighborhood-search .title {
  font-size: 24px;
  text-transform: uppercase;
}

.home-neighborhood-search .ns-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1em;
  padding: 0 1rem;
  margin-top: 3em;
}

.home-neighborhood-search .ns-features > div {
  text-align: left;
  border-left: 1px solid #fff;
  padding: 2rem 1rem;
}

.home-neighborhood-search .ns-feature-title {
  font-size: 18px;
  margin-bottom: 1rem;
}

.home-neighborhood-search .ns-feature-desc {
  color: #acabab;
  font-size: 12px;
}

.measurements-container,
.other-features {
  width: 100%;
  margin: 0 auto;
  padding: 2.5em;
}

.other-features {
  position: relative;
}

.other-features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/landing-page/curly-line-2.svg') no-repeat;
  background-size: contain;
  background-position: center calc(100% - 30px);
  z-index: 0;
}

.other-features .max-w-screen-lg {
  position: relative;
  z-index: 2;
}

.landing-home-container .measure-list,
.landing-home-container .gems-list,
.landing-home-container .features-list,
.landing-home-container .impact-list,
.landing-home-container .partners-list {
  display: grid;
  gap: 10px;
}

.measure-list,
.gems-list {
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
  justify-content: center;
  margin-top: 2rem;
}

.impact-list,
.partners-list {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.measure-item,
.gem-item,
.impact-item,
.partner-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
}

.other-feature-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  text-align: center;
  gap: 1.5em;
}

.other-feature-item:nth-child(even) {
  flex-direction: row-reverse;
}

.other-feature-item svg {
  height: 50px;
  width: auto;
}

.other-feature-item .ratings-icon svg {
  height: 30px !important;
}

.landing-view-btn {
  width: fit-content;
  color: #fff;
  background: none;
  font-weight: 400;
  border-radius: 0;
  border: none;
  border: 1px solid #fff;
  padding: 0.5rem 2rem;
  margin: 3rem 0 0;
  font-family: var(--font-family);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.landing-view-btn:hover {
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #000;
}

.measure-item p,
.gem-item p,
.other-feature-item p {
  margin-top: 10px;
  color: #fff;
}

.measurements-container {
  color: #000000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 3.5em 2.5em calc(2.5em + 30px);
}

.measurements-container .slick-slider {
  max-width: 97.5%;
  margin: 0 auto;
}

.measurements-container .slick-dots li.slick-active button {
  background: #000 !important;
}

.measurements-container .number {
  font-size: 80px;
}

.measurements-container .description {
  max-width: 450px;
}

.measurements-container .arrow-wrapper {
  position: absolute;
  left: 50%;
  bottom: -27px;
  transform: translateX(-50%);
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.measurements-container .arrow {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.landing-home-container .measurement-title {
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
}

.landing-home-container .measurements {
  margin-bottom: 2.5em;
}
.landing-home-container .measurements h2,
.landing-home-container .gems h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
}

.landing-home-container .measurements span,
.landing-home-container .gems span {
  font-size: 16px;
}

.landing-home-container .measurements svg,
.landing-home-container .gems svg {
  height: 40px;
  width: auto;
}

.landing-home-container .measurements svg path,
.landing-home-container .gems svg path {
  fill: #fff;
}

.landing-title {
  font-size: 28px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.landing-home-container .other-features {
  background-color: #211f1f;
  color: #fff;
  padding: 4em 2.5em;
}

.landing-home-container .features-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5em 5em;
  max-width: 1250px;
  margin: 0 auto;
}

.impact-startup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0;
  padding: 4rem 1rem;
}

.impact-startup .landing-title {
  margin-bottom: 0;
}

.impact-startup .description {
  font-size: 14px;
  max-width: 80%;
  margin-bottom: 3em;
}

.impact-startup .description,
.impact-item p {
  font-family: Inter;
  font-weight: 400;
}

.impact-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem 0;
  font-size: 14px;
  text-align: left;
}

.impact-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.impact-item p {
  margin: 0 auto;
}

.landing-home-container .partners {
  background-color: #211f1f;
  color: white;
  padding: 3rem 1rem;
}

.partner-item {
  gap: 1rem 0;
  justify-content: flex-start;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}

.partner-item img {
  width: auto;
  height: 100px;
}

.partner-item h3 {
  margin: 10px 0;
  margin: 0;
}

.partner-item p {
  margin: 0;
  font-size: 14px;
}

.partner-item a {
  text-decoration: none;
}

.partner-item .landing-view-btn {
  margin-top: auto;
}

/* TAB STYLING */
.neighborhood-intel-tabs {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 4rem 1rem;
}

.neighborhood-intel-tabs h1 {
  color: #414141;
}

.neighborhood-intel-tabs .slick-slider,
.neighborhood-intel-tabs .slick-list,
.neighborhood-intel-tabs .slick-track {
  max-height: 500px;
}

.landing-home-container .tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.landing-home-container .tab {
  margin: 0 10px;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: 400;
  color: #211f1f;
  transition: all 0.3s ease;
}

.tab.active {
  background: #000000;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 500;
}

.tab-content img {
  width: 100%;
  max-width: 1050px;
  max-height: 500px;
  margin: 0 auto;
  object-fit: contain;
}

.neighborhood-intel-tabs .scoring {
  display: flex !important;
  justify-content: center;
  gap: 0 1rem;
  padding: 5rem 0;
}

.neighborhood-intel-tabs .scoring img {
  height: 245px;
  width: auto;
  margin: 0;
  object-fit: cover;
}

.testimonials-wrapper {
  display: flex;
  justify-content: center;
  color: #ffffff;
  padding: 2.5em;
  background-image: url('../../assets/landing-page/gradient-bg.jpg');
}

.testimonials-wrapper img {
  border-radius:  50%;
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.walking-td {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

@media (max-width: 768px) {
  .landing-home-container {
    overflow-x: hidden;
  }

  .home-banner {
    padding: 0;
    height: 100svh;
  }

  .home-banner .banner-content {
    padding: 1rem 0;
    gap: 0.5rem;
  }

  .landing-home-container h1,
  .banner-content .slogan {
    font-size: 24px;
  }

  .banner-content .features {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8pt 0;
    font-size: 14px;
    background: none;
  }

  .banner-content .features a {
    padding: 20px;
    background-color: #000000CC;
  }

  .banner-content .search-address-input {
    max-width: 350px;
  }

  .home-neighborhood-search .ns-features {
    grid-template-columns: 1fr;
  }

  .home-neighborhood-search .ns-features > div {
    padding-right: 0;
    margin-left: 1rem;
  }

  .landing-home-container .tabs {
    flex-wrap: wrap;
  }

  .landing-home-container .tab {
    margin: 0;
    padding: 10px;
  }

  .neighborhood-intel-tabs {
    padding: 2rem 1rem;
  }

  .neighborhood-intel-tabs .scoring {
    flex-direction: column;
    gap: 1rem 0;
    padding: 1rem 0;
  }

  .neighborhood-intel-tabs .scoring img {
    max-height: 215px;
  }

  .walking-td {
    height: 100svh;
    max-height: 100svh;
  }

  .measurements-container {
    padding: 2rem 1rem 4rem;
  }

  .measurements-container .arrow-wrapper {
    max-width: 365px;
  }

  .measurements-container .measurements,
  .measurements-container .gems {
    padding: 0;
  }

  .landing-home-container .features-list {
    grid-template-columns: 1fr;
  }

  .other-feature-item {
    width: 100%;
    margin: 10px auto;
    justify-content: center;
  }
}